<template>
  <div class="container">
    <div class="epic-pay">
      <div class="epic-pay__title">
        Thank you.
      </div>
      <div class="epic-pay__text">
        Your application has been submitted to EpicPay.
      </div>
      <div class="epic-pay__text">
        Your will hear from them shortly.
      </div>
      <div class="epic-pay__text">
        Once your account is approved, your Giving module will be activated automatically.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpicPay',
};
</script>

<style lang="scss">
  @import 'assets/sass/utils/variables';

  .epic-pay {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 100px;

    &__title {
      font: 25px $font-global-bold;
      margin: 0 0 40px;
    }

    &__text {
      font: 25px / 1.5 $font-global
    }

  }

</style>
